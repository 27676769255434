<template>
  <div class="bg-white px-25 py-25 md:px-65 md:py-40 rounded-5">
    <div class="mb-20">
      <h1 class="text-dark-cl-20 text-1xl sm:text-3xl mb-20">Congratulations!</h1>
      <p class="text-grey-fp-60 mb-20">
        <span>
          You've successfully verified your email.
        </span>
      </p>
    </div>

    <div>
      <AppButton 
        type="primary" 
        :size="screen.width < 640 ? 'medium' : 'large'"
        class="w-full mb-20 mt-35" 
        @click="router.push({ name: routesNames.login })"
      >
        Go to Login
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useScreen } from 'vue-screen';

import AppButton from '@/components/stateless/AppButton.vue';

import { routesNames } from '@/router';

export default defineComponent({
  name: 'Greeting',

  components: { AppButton },

  setup() {
    const router = useRouter();
    const screen = useScreen();

    return {
      routesNames,
      router,
      
      screen,
    };
  }

});
</script>