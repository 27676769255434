
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useScreen } from 'vue-screen';

import AppButton from '@/components/stateless/AppButton.vue';

import { routesNames } from '@/router';

export default defineComponent({
  name: 'Greeting',

  components: { AppButton },

  setup() {
    const router = useRouter();
    const screen = useScreen();

    return {
      routesNames,
      router,
      
      screen,
    };
  }

});
